.product-faq-container {
    margin: 50px auto;
    max-width: 1000px;
    color: white;
    padding: 40px;
    
  }

  .faq-title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Adjust gap between items */
  }
  
  /* Outer container for each item */
  .faq-item-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.15));
    /* Rounded corners */
    border-radius: 25px;
    /* Shiny gradient border */
    border: 2px solid;    
    /* Subtle inner glow/shadow and blur for the glass effect */
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
  }
  
  .faq-item-container.left {
    margin-left: -10rem;
  }
  
  .faq-item-container.right {
    margin-left: auto;
    margin-right: -5rem;
  }
  
  /* Hover effect for individual containers */
  .faq-item-container:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  }
  
  /* Inner container for question and answer */
  .faq-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Question styling */
  .faq-question {
    font-size: 20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: rgba(255, 255, 255, 0.8);
  }
  
  /* Answer styling */
  .faq-answer {
    font-size: 18px;
    line-height: 1.8;
    font-family: "Poppins", sans-serif;
    color: rgba(255, 255, 255, 0.9);
  }
  