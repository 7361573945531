/* The grid container */
.stats-grid-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 100px);
    gap: 10px; /* Adjust gap between cells as needed */
    width: 100%;
    max-width: 1200px;
    height: 500px; /* Define overall height */
    margin: 40px auto;
    background: transparent;
    
  }
  
  /* Base styling for each stat square */
  .stat-grid-item {
    position: relative;
    background: rgba(0, 0, 0, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    overflow: hidden;
    /* Optional subtle floating effect */
    animation: float 3s ease-in-out infinite;
    overflow: hidden;
  }
  
  /* Floating animation (optional) */
  @keyframes float {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
  }
  
  /* Ensure the text is on top */
  .stat-content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    overflow: hidden;
  }
  
  /* Font styling for the stat number */
  .stat-number {
    font-size: 48px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  /* Font styling for the stat label */
  .stat-label {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    min-width: 200px;
    font-style: normal;
    color: #ddd;
    text-align: center;
    padding: 8px 10px;
    overflow: hidden;
    /* Glassy background with a subtle gradient */
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.15));
    /* Rounded corners */
    border-radius: 25px;
    /* Shiny gradient border */
    border: 2px solid;    
    /* Subtle inner glow/shadow and blur for the glass effect */
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
  }
  
  
  /* Item 1: blocked botnet */
  .stat-item-1 {
    grid-area: 1 / 1 ;
  }
  
  /* Item 2: user scanned every hour */
  .stat-item-2 {
    grid-area: 4 / 3 ;
  }
  
  /* Item 3: automatic ban */
  .stat-item-3 {
    grid-area: 1 / 6 ;
  }
  
  /* Item 4: trust vector */
  .stat-item-4 {
    grid-area: 3 / 2 ;
  }
  
  /* Item 5: data analyzed every hour */
  .stat-item-5 {
    grid-area:  4 / 6 ;
  }
  
  /* Item 6: Users protected every day */
  .stat-item-6 {
    grid-area: 2 / 4 ;
  }
  
  /* Item 7: blocked VPNs */
  .stat-item-7 {
    grid-area: 1 / 3 ;
  }
  
  /* Item 8:  */
  .stat-item-8 {
    grid-area: 4 / 1 ;
}