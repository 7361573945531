.status-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(49deg, rgba(15, 0, 48, 1), rgba(22, 0, 66, 1), rgb(58, 0, 70));
    color: white;
  }
  
  .status-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .status-diagram {
    width: 100%;
    height: 80%;
    position: relative;
  }
  
  .react-flow__node-default {
    background: #2b2b2b;
    color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    --xy-background-pattern-dots-color-default:#ff0000;
  }

  .react-flow {
    --xy-background-pattern-line-color-default: #ff0000;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: -98;
    }
  }
  