/* General Dashboard Layout */
.dashboard-layout {
    display: flex;
    height: 100vh; /* Full viewport height */
    background: linear-gradient(49deg, rgba(15, 0, 48, 1) 0%, rgba(22, 0, 66, 1) 60%, rgb(58, 0, 70) 100%);
    color: white;
    font-family: 'Poppins', sans-serif;
    overflow: hidden; /* Prevent scrolling */
  }
  
  
  /* Content Area */
  .content {
    color: white;

    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column; /* Stacked layout */
    gap: 10px;
    align-items: stretch; /* Ensure child elements fit container width */
    justify-content: space-evenly; /* Distribute space evenly */
  }
  
  /* Stats Row */
  .stats-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }
  
  /* Stats Container */
  .stat-card {
    flex: 1; /* Make all cards equal width */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 16px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  }
  
  .stat-card h3 {
    margin-bottom: 5px;
    font-size: calc(1vw + 8px); /* Dynamically scale font size */
  }
  
  .stat-card p {
    font-size: calc(1.2vw + 6px); /* Dynamically scale font size */
    font-weight: bold;
    margin: 0;
  }
  

 /* Graph Container */
.content-container {
    flex: 1; /* Takes available height */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden; /* Prevent content overflow */
  }
  
  .chart-container {
    position: relative;
    width: 100%;
    height: calc(60vh - 50px); /* Dynamically adjust height */
  }
  
  .chartjs-render-monitor {
    max-height: 100%; /* Ensure the graph fits */
    margin: 0 auto;
  }
  
  
  /* Graph Styling */
  .chartjs-render-monitor {
    max-height: calc(100% - 20px); /* Ensure the graph scales correctly */
    max-width: calc(100% - 20px);
    margin: 0 auto; /* Center the graph */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .sidebar {
      flex: 0 0 20%; /* Adjust sidebar width for smaller screens */
      max-width: 200px;
    }
  
    .menu-item {
      padding: 8px;
    }
  
    .menu-item .menu-icon {
      width: 20px;
      height: 20px;
    }
  
    .stats-row {
      flex-direction: column; /* Stack stats on small screens */
    }
  
    .stat-card {
      font-size: 14px;
    }
  
    .content-container {
      margin-top: 10px;
    }
  }
  