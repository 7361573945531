.layout {
    display: flex;
    height: 100vh;
    background: linear-gradient(49deg, rgba(15, 0, 48, 1) 0%, rgba(22, 0, 66, 1) 60%, rgb(58, 0, 70) 100%);
    color: white;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Antispoof Main Content */
  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  /* Search Bar */
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }
  
  /* Player List */
  .player-list {
    margin-bottom: 20px;
  }
  
  .player-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .player-list li {
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  .player-list li:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.02);
  }
  
  .player-list .selected {
    background-color: rgba(106, 13, 173, 0.3);
  }
  
  /* Player Details */
  .player-details {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  }
  
  .ban-button {
    width: 100%;
    background-color: #d32f2f;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .ban-button:hover {
    background-color: #b71c1c;
  }
  