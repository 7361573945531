
  /* Sidebar Styling */
  .sidebar {
    font-family: 'Poppins', sans-serif;
    color: white;
    flex: 0 0 15%; /* Sidebar width */
    max-width: 250px;
    min-width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .tadm {
    color: red;
    font-weight: 600;
    font-size: 1.3rem;
  }

  .logo {
    font-size: 1.5vw; /* Responsive font size */
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 100%;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.2s;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: rgba(106, 13, 173, 0.8);
    transform: scale(1.05);
  }
  
  .menu-item .menu-icon {
    width: 2vw; /* Responsive width */
    height: 2vw; /* Responsive height */
    max-width: 40px; /* Prevent growing too large */
    margin-right: 10px;
    fill: white;
  }
  
  .menu-item:hover .menu-icon {
    fill: #6a0dad;
  }