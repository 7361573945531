/* Container styling */
.product-selection-container {
    padding: 50px 0;
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Card styling */
  .ant-card {
    color: white;
    background: rgba(255, 255, 255, 0.1); /* Slight translucent white for contrast */
    backdrop-filter: blur(10px); /* Apply the blur effect */

    font-size: 48px;
    font-family: "Poppins", sans-serif; /* Default font family */
    font-weight: 400; /* Thin weight globally */
    font-style: normal; /* Ensure no italics globally */

    text-align: left;
    border-radius: 10px 50px 50px 50px;
    backdrop-filter: blur(10px);
    border: 0px solid rgba(255, 255, 255, 0.274); /* White border */
    border-top: 20px solid white !important; /* Force the top border to be white */
    padding: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  
  /* Custom shadows for each card */
  .card-lite {
    box-shadow: 0 4px 16px rgba(255, 255, 255, 0.4); /* Green shadow for Lite */
    border-top: 20px solid rgba(255, 255, 255, 0.733) !important; /* Force the top border to be white */
    border-left: 1px solid rgba(255, 255, 255, 0.733) !important; /* Force the top border to be white */


  }
  
  .card-lite:hover {
    box-shadow: 0 8px 24px rgba(255, 255, 255, 0.6);
    border-top: 20px solid rgb(255, 255, 255) !important;
    border-left: 1px solid rgb(255, 255, 255) !important; /* Force the top border to be white */

  }
  
  .card-intercept {
    box-shadow: 0 4px 16px rgba(255, 184, 51, 0.4); /* Orange shadow for Intercept */
    border-top: 20px solid rgb(184, 141, 0) !important;
    border-left: 1px solid rgb(184, 141, 0) !important; /* Force the top border to be white */

    
  }
  
  .card-intercept:hover {
    box-shadow: 0 8px 24px rgba(255, 177, 32, 0.6);
    border-top: 20px solid rgb(248, 196, 24) !important;
    border-left: 1px solid rgb(248, 196, 24) !important; /* Force the top border to be white */

  }
  
  .card-sentryx {
    box-shadow: 0 4px 16px rgba(51, 15, 255, 0.4); /* Red shadow for SentryX Shield */
    border-top: 20px solid rgb(29, 0, 196) !important;
    border-left: 1px solid rgb(29, 0, 196) !important; /* Force the top border to be white */

  }
  
  .card-sentryx:hover {
    box-shadow: 0 8px 24px rgba(38, 0, 255, 0.6);
    border-top: 20px solid rgb(50, 13, 255) !important;
    border-left: 1px solid rgb(50, 13, 255) !important; /* Force the top border to be white */

  }
  
  /* Title styling */
  .product-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  /* Price styling */
  .product-price {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -15px;
  }
  
  .product-line {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: -5px;
  }

  .product-line2 {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: -5px;
  }


  /* Features list styling */
  .product-features {
    list-style: none; /* Remove default bullets */
    padding: 0;
    margin-bottom: 16px;
  }
  
  .product-feature {
    font-size: 18px;
    margin-bottom: 16px;
    display: flex; /* Align icon and text */
    align-items: center;
  }
  
  .product-feature::before {
    content: '';
    display: inline-block;
    width: 25px; /* Set the size of the image */
    height: 25px;
    margin-right: 10px; /* Space between icon and text */
    background-image: url('https://cdn.data-system.org/spg/point.png');
    background-size: contain; /* Ensure the image scales properly */
    background-repeat: no-repeat; /* Prevent repeating */
    background-position: center; /* Center the image */
  }
  
  /* Button styling */
  .ant-btn {
    background-color: transparent;
    border: 1px solid;
    border-radius: 25px;
    width: 50%;
    padding: 10px 0;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .ant-btn:hover {
    background-color: #50505000;
    transform: scale(1.05);
    box-shadow: 0 2px 8px rgba(255, 255, 255, 0.384); /* Intense shadow on hover */
  }
  