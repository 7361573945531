/* General page styling */
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgb(15, 0, 48);
  background: linear-gradient(49deg, rgba(15, 0, 48, 1) 0%, rgba(22, 0, 66, 1) 60%, rgb(58, 0, 70) 100%);
  color: white;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

/* Back Arrow Container */
.back-arrow-container {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1000;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  border: 3px dotted rgb(255, 255, 255); /* Debug border */
  border-radius: 50%;
  display: flex;
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  overflow: hidden; /* Prevent the image from spilling out */
  transition: all 0.3s ease;
}

.back-arrow-container:hover {
  border: 3px dashed rgb(255, 255, 255); /* Debug border on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Back Arrow Icon */
.back-arrow-icon {
  width: 100%; /* Fit the container */
  height: 100%; /* Fit the container */
  object-fit: cover; /* Maintain the aspect ratio */
  scale: 0.7;
  transition: transform 0.3s ease;
}

.back-arrow-icon:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

/* Form container */
.form-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
  opacity: 1;
}

/* Enter and Exit States for Animation */
.form-container.enter {
  
  transform: translateY(0);
  opacity: 1;
}

.form-container.exit {
  transform: translateY(-20px);
  opacity: 0;
}

/* Title styling */
.login-title {
  color: white !important;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
}

/* Form styling */
.login-form .ant-form-item-label > label {
  color: white;
}

.login-form .ant-input,
.login-form .ant-input-password {
  background: rgba(255, 255, 255, 0.2) !important;
  border: none;
  border-radius: 8px;
  color: white;
}

.login-form .ant-input::placeholder,
.login-form .ant-input-password::placeholder {
  
  color: rgba(255, 255, 255, 0.6);
}

.login-form .ant-input:hover,
.login-form .ant-input-password:hover {
  border: none;
  background: rgba(255, 255, 255, 0.25);
}

/* Button styling */
.form-button {
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #6a0dad;
  color: white;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s;
}

.form-button:hover {
  background: #7a1dad;
}

/* Toggle text styling */
.toggle-text {
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.toggle-link {
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.toggle-link:hover {
  color: #ddd;
}
