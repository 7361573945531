.layout {
    display: flex;
    height: 100vh;
    background: linear-gradient(49deg, rgba(15, 0, 48, 1) 0%, rgba(22, 0, 66, 1) 60%, rgb(58, 0, 70) 100%);
  }
  
  .sidebar {
    width: 250px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .main-content {
    flex: 1;
    overflow-y: auto;
  }
  