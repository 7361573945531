
body {
  background-color: #31102F;
	background: radial-gradient(ellipse at center, rgba(49,16,47,1) 0%, rgba(40,11,41,1) 100%);
}

.homepage-layout {
  
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures the layout spans the entire height */
    background: rgb(15,0,48);
    background: linear-gradient(49deg, rgb(0, 0, 0) 0%, rgb(1, 0, 58) 60%, rgb(0, 0, 0) 100%);
  }
  
  ::-webkit-scrollbar {
    display: none;
}

#stars {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none; /* Prevent canvas from blocking clicks */
}


.homepage-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}
  
@keyframes color-scroll-reverse {
  0% {
    background-position: 200% 50%; /* Start from the far right */
  }
  100% {
    background-position: 0% 50%; /* End on the far left */
  }
}

.homepage-title {
  position: fixed;
  text-align: left;
  margin-left: 1%;
  width: 100%;
  font-size: 3em;
  font-family: monospace;
  font-weight: 800;
  font-style: normal;
  background: linear-gradient(
    90deg,
    rgb(69, 2, 204),
    rgb(255, 255, 255),
    rgb(69, 2, 204),
    rgb(255, 255, 255),
    rgb(69, 2, 204)
  );
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: color-scroll-reverse 5s linear infinite;
}
  
  .homepage-description {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 30px;
    font-family: "Poppins", sans-serif; /* Default font family */
    font-weight: 400; /* Thin weight globally */
    font-style: normal; /* Ensure no italics globally */
  }
  
  .homepage-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(49deg, rgba(15, 0, 48, 0.5) 0%, rgba(22, 0, 66, 0.5) 60%, rgba(58, 0, 70, 0.5) 100%);
    backdrop-filter: blur(10px); /* Apply the blur effect */
    z-index: 2;
    border-top: 1px solid rgba(255, 255, 255, 0.233);
    color: white;
    padding: 5px 10px;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .f1 {
    font-size: 13px;
    padding: 5px 10px;
    flex-shrink: 0; /* Prevents the f1 content from wrapping to new lines */
    margin-right: auto; /* Pushes f2 to the center */
    white-space: nowrap; /* Ensures the text stays on one line */
  }
  
  .f2 {
    font-size: 20px;
    position: absolute;
    left: 50%; /* Position in the center of the footer */
    transform: translateX(-50%); /* Adjust to center the text exactly */
    text-align: center;
  }
  
/* User Icon Container */
.user-icon-container {
    position: fixed;
    top: 30px; /* Adjust top distance */
    right: 30px; /* Adjust right distance */
    z-index: 1000; /* Ensure it stays above other elements */
    min-height: 60px;
    min-width: 60px;
    max-height: 50px;
    max-width: 50px;
    border: 3px dotted rgb(255, 255, 255); /* Debug border */
    border-radius: 50%;
    display: flex; /* Add flex to center the image */
    align-items: center; /* Center image vertically */
    justify-content: center; /* Center image horizontally */
    overflow: hidden; /* Ensure the image doesn't overflow the container */
    transition: all 0.3s ease;
  }

  .user-icon-container:hover {
    position: fixed;
    top: 30px; /* Adjust top distance */
    right: 30px; /* Adjust right distance */
    z-index: 1000; /* Ensure it stays above other elements */
    min-height: 60px;
    min-width: 60px;
    max-height: 50px;
    max-width: 50px;
    border: 3px dashed rgb(255, 255, 255); /* Debug border */
    border-radius: 50%;
    display: flex; /* Add flex to center the image */
    align-items: center; /* Center image vertically */
    justify-content: center; /* Center image horizontally */
    overflow: hidden; /* Ensure the image doesn't overflow the container */
    transform: scale(1.1); /* Slightly enlarge on hover */
    transition: all 0.3s ease;
  }
  
  /* User Icon */
  .user-icon {
    color: white;
    width: 100%; /* Make the image fit the container width */
    height: 100%; /* Make the image fit the container height */
    object-fit: cover; /* Ensure the image maintains aspect ratio */
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .user-icon:hover {
    transform: scale(1.10); /* Slightly enlarge on hover */
  }